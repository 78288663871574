import {Communication} from '../../../shared/Services/communication';
import {HttpClient} from '@angular/common/http';
import {Http} from '@angular/http';
import {Observable} from 'rxjs';
export class HelpCommunication extends Communication {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  getHelp(fileName): Observable<string> {

    const url = this.authorUrl + 'HilfeHtmlFiles/' + fileName + '.html';
    return this.httpClient.get(url, {responseType: 'text'}) as Observable<string>;
  }



} 