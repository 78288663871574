import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers, HttpModule, Jsonp } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
export class Communication {
  //  protected controllerUrl = 'http://www.juslab.de/AutorenSystemDialog/';
  protected htkUrl = 'http://www.juslab.de/AutorenSystemDialog/';
  protected authorUrl = '';
  protected authenticationUrlAdmin = ' ';

  protected authenticationUrlClient = ' ';


  protected dialogUrl = '';
  public billUrl = '';
  public folderPath;
  protected options: RequestOptions;
  constructor(protected httpClient: HttpClient) {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', '*');
    // tslint:disable-next-line:max-line-length
    headers.append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers');
    this.htkUrl = 'http://localhost/HTK/controller.php?';

    this.dialogUrl = 'http://localhost/ExpertenSystemA/Controller.php';
    this.authorUrl = 'https://www.juslab.de/AutorenSystemDialog/';
    //    this.authenticationUrl = 'http://localhost/AuthenticationServer/controller.php';
    this.authenticationUrlAdmin = 'http://localhost/AuthenticationServer/AdminController.php';
    this.authenticationUrlClient = 'http://localhost/AuthenticationServer/ClientController.php';
    this.billUrl = 'http://localhost/Buchhaltung/controller.php?';
    this.folderPath = 'https://www.juslab.de/';
    // this.rootUrl = 'http://juslab.de/AutorenSystemDialog/';
    this.options = new RequestOptions({ headers: headers });


    this.htkUrl = 'https://www.juslab.de/HTK/controller.php?';

    this.dialogUrl = 'https://www.juslab.de/ExpertenSystem/Controller.php';
    this.authorUrl = 'https://www.juslab.de/AutorenSystemDialog/';
    this.authenticationUrlAdmin = 'https://www.juslab.de/AuthenticationServer/AdminController.php';
    this.authenticationUrlClient = 'https://www.juslab.de/AuthenticationServer/ClientController.php';
    this.billUrl = 'https://www.juslab.de/Buchhaltung/controller.php?';

  }
}
