import { SharedModule } from '../shared/shared.module';
import { CoreCommunicationService } from './Services/CommunicationService/corecommunicationservice';
import { NgModule } from '@angular/core';
import { TokenInterceptor } from '../shared/Services/AuthenticationService/tokeninterceptor';
import { LogInComponent } from './View/log-in/log-in.component';
import { RouterModule, Routes } from '@angular/router';
import { LOCALE_ID } from '@angular/core';

import { QuillModule } from 'ngx-quill';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientJsonpModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './View/all-headers/header/header.component';
import { FooterComponent } from './View/footer/footer.component';
import { AuslrHeaderComponent } from './View/all-headers/auslr-header/auslr-header.component';
import { AuthorTopbarViewComponent } from './View/all-headers/author-topbar-view/author-topbar-view.component';
import { StarHeaderComponent } from './View/all-headers/star-header/star-header.component';
import { LogoutHeaderButtonComponent } from './View/all-headers/logout-header-button/logout-header-button.component';
import { TestComponent } from '../shared/test/test.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);


import { GotToAuthorAreaComponent } from './View/all-headers/got-to-author-area/got-to-author-area.component';
import { AdminGuard } from './Guards/adminguard';
import { ClientGuard } from './Guards/clientguard';
import { JwtInterceptor } from '../shared/Services/AuthenticationService/jwtinterceptor';
import { AuthorGuard } from './Guards/authorguard';
import { LoginGuard } from './Guards/loginguard';
import { BookingHeaderComponent } from './View/all-headers/booking-header/booking-header.component';
import { PublicHeaderComponent } from './View/all-headers/public-header/public-header.component';
import { DialogChooseProduktComponent } from '../booking/View/PopUp/dialog-choose-produkt/dialog-choose-produkt.component';


export function tokenGetter() {
    return localStorage.getItem('token');
}





const APP_ROUTES: Routes = [
    {
        path: 'author/:projekt',
        canActivate: [AuthorGuard],
        loadChildren: '../author/author.module#AuthorModule',
    },
    {
        path: 'dialog/:projekt',
        canActivate: [LoginGuard],
        loadChildren: '../dialog-system/dialog-system.module#DialogSystemModule',
    },
    {
        path: 'htk/:projekt',
        canActivate: [LoginGuard],
        loadChildren: '../htk/htk.module#HtkModule',
    },
    {
        path: 'account/:clientId',
        canActivate: [ClientGuard],
        loadChildren: '../account/account.module#AccountModule',
    },
    {
        path: 'public-area',
        loadChildren: '../public-area/public-area.module#PublicAreaModule',
    },
    {
        path: 'admin-area',
        canActivate: [AdminGuard],
        loadChildren: '../admin/admin.module#AdminModule',
    },
    {
        path: 'booking-area',
        canActivate: [AdminGuard],
        loadChildren: '../booking/booking.module#BookingModule',
    },
    {
        path: '',
        loadChildren: '../public-area/public-area.module#PublicAreaModule',
    },
    {
        path: '**',
        loadChildren: '../public-area/public-area.module#PublicAreaModule',
    }
];




@NgModule({
    exports: [
        RouterModule,
        FooterComponent,
        HeaderComponent,
    ],
    declarations: [
        LogInComponent,
        FooterComponent,
        HeaderComponent,
        AuthorTopbarViewComponent,
        AuslrHeaderComponent,
        StarHeaderComponent,
        LogoutHeaderButtonComponent,
        TestComponent,
        GotToAuthorAreaComponent,
        BookingHeaderComponent,
        PublicHeaderComponent,
    ],
    imports: [
        SharedModule,
        RouterModule.forRoot(APP_ROUTES),
        HttpClientModule,
        ReactiveFormsModule,
        NoopAnimationsModule,
        QuillModule,
        FormsModule,
        HttpClientJsonpModule,
    ],
    providers: [
        CoreCommunicationService,
        AdminGuard,
        ClientGuard,
        LoginGuard,
        AuthorGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },

        { provide: LOCALE_ID, useValue: 'de-DE' }

    //    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' }

    ],


})

export class CoreModule { }
