import {AuthenticationService} from '../../../../shared/Services/AuthenticationService/authentication.service';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-logout-header-button',
  templateUrl: './logout-header-button.component.html',
  styleUrls: ['./logout-header-button.component.css']
})
export class LogoutHeaderButtonComponent implements OnInit {
  constructor(private auth: AuthenticationService) {}

  ngOnInit() {
  }
  logOut() {
    this.auth.logOut().subscribe();
  }

}
