
import {Communication} from '../../../shared/Services/communication';
import {FullDialogInfo} from '../../../shared/entities/Dialog/dialoginfo';
import {FullVariable} from '../../../shared/entities/Dialog/variable';
import {HttpClient} from '@angular/common/http';
import {Http} from '@angular/http';
import {Observable} from 'rxjs';

export class VariableCommunication extends Communication {

  constructor(protected http: HttpClient) {
    super(http);
  }

  putVarChanges(jsonVar, varId): Observable<any> {
    const url = this.authorUrl + 'variable/' + varId;
    return this.http.put(url, JSON.stringify(jsonVar)) as Observable<any>;

  }

  postNewVariable(jsonDialog: any): Observable<any> {
    const url = this.authorUrl + 'variable/';
    return this.http.post(url, JSON.stringify(jsonDialog)) as Observable<any>;
  }

  getPotentialCompromisedDialogsByVarDelete(varId: number): Observable<Array<FullDialogInfo>> {
    const url = this.authorUrl + 'delete-variable-request/' + varId;
    return this.http.get(url) as Observable<Array<FullDialogInfo>>;
  }

  getMyVariables(authorId): Observable<Array<FullVariable>> {
    const url = this.authorUrl + 'variable/author/' + authorId;
    return this.http.get(url) as Observable<Array<FullVariable>>;
  }

  getMyVariablesByPageAndSearch(page: number, nameString, sort, authorId): Observable<Array<FullVariable>> {
    const url = this.authorUrl + 'search/var/author/' + authorId + '/page/' + page + '/query/' + nameString;
    return this.http.post(url, JSON.stringify(sort)) as Observable<Array<FullVariable>>;
  }

  getAllVariables(): Observable<Array<FullVariable>> {
    const url = this.authorUrl + 'variable/';
    return this.http.get(url) as Observable<Array<FullVariable>>;
  }

  getAllVariablesByPageAndSearch(page: number, nameString, sort): Observable<Array<FullVariable>> {
    const url = this.authorUrl + 'search/var/page/' + page + '/query/' + nameString;
    return this.http.post(url, JSON.stringify(sort)) as Observable<Array<FullVariable>>;
  }


  getSingleVariable(varId: number): Observable<Array<FullVariable>> {
    const url = this.authorUrl + 'variable/' + varId;
    return this.http.get(url) as Observable<Array<FullVariable>>;
  }


  deleteVariable(varId: number): Observable<any> {
    const url = this.authorUrl + 'variable/' + varId;
    return this.http.delete(url) as Observable<Array<FullVariable>>;
  }


}
