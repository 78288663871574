import { AuthenticationCommunication } from './Services/AuthenticationService/authenticationcommunication';
import { CoreModule } from '../core/core.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginCredentialsDialogComponent } from '../public-area/Dialog/login-credentials-dialog/login-credentials-dialog.component';
import { AuthenticationService } from './Services/AuthenticationService/authentication.service';
import { CoreCommunicationService } from '../core/Services/CommunicationService/corecommunicationservice';
import { RequestYesNoComponent } from './Services/PopUpService/request-yes-no/request-yes-no.component';
import { HttpClientJsonpModule } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {
  MatInputModule,
  MatCommonModule,
  MatButtonModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MAT_DATE_FORMATS,
  NativeDateModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatIconModule,
  MatCardModule,
  MatSidenavModule,
  MatTooltipModule,
  MatSortModule,
  MatTableModule,
  MatDialogModule,
  MatTreeModule,
  MatListModule,
  MatSelectModule,
  MatOptionModule,
  MatTabsModule
} from '@angular/material';
import { BillContainerComponent } from './Views/bill-container/bill-container.component';
import { ViewHelpComponent } from './Views/view-help/view-help.component';
import { ViewVerdicComponent } from './Views/view-verdic/view-verdic.component';
import { SharedCommunicationService } from './Services/CommunicationService/shared-communication.service';
import { ViewBillButtonComponent } from './Views/Button/view-bill-button/view-bill-button.component';
const materialModules = [
  MatInputModule,
  MatCommonModule,
  MatButtonModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatListModule,
  MatCheckboxModule,
  MatTreeModule,
  NativeDateModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatProgressBarModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatButtonModule,
  MatIconModule,
  MatCardModule,
  MatSidenavModule,
  MatTooltipModule,
  MatSortModule,
  MatTableModule,
  MatDialogModule,
  MatOptionModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatTabsModule,
];



@NgModule({
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,
    materialModules
  ],
  providers: [
    AuthenticationService,
    CoreCommunicationService,
    SharedCommunicationService,
  ],
  declarations: [
    ViewHelpComponent,
    ViewVerdicComponent,
    RequestYesNoComponent,
    BillContainerComponent,
    ViewBillButtonComponent
  ],
  entryComponents: [
    RequestYesNoComponent,
  ],
  exports: [
    ViewHelpComponent,
    ViewVerdicComponent,
    materialModules,
    RequestYesNoComponent,
    ReactiveFormsModule,
    BillContainerComponent,
    ViewBillButtonComponent
  ],


})
export class SharedModule { }
