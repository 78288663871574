
import {AuthenticationResult, Product, ClientInfo} from '../../entities/authenticationresult';
import {CoreCommunicationService} from '../../../core/Services/CommunicationService/corecommunicationservice';
import {JWTHandler} from './jwthandler';
import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Jsonp} from '@angular/http';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  public routeBeforeLogIn = '';
  public eddit = false;
  private product: Product;
  public jwtHandler: JWTHandler;
  public clientInfo: ClientInfo;
  public cachedRequests: Array<HttpRequest<any>> = [];


  constructor(private router: Router, private comm: CoreCommunicationService) {
    if (localStorage['fullAuth'] && localStorage['fullAuth'] !== 'null') {
      const obj = JSON.parse(localStorage['fullAuth']) as AuthenticationResult;
      this.jwtHandler = new JWTHandler(obj.jwt);
      this.clientInfo = obj.clientInfo;
    }
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public retryFailedRequests(): void {
    if (this.cachedRequests.length > 0) {
      this.comm.sendRequest(this.cachedRequests.pop()).subscribe(
        data => {
          window.location.reload();
        });
    }
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (typeof token === 'undefined' || token === null || this.jwtHandler === null || typeof this.jwtHandler === 'undefined') {
      return false;
    }
    return !this.jwtHandler.isTokenExpired();
  }
  //  private jumpBackToBefore() {
  //    if (this.routeBeforeLogIn.length > 0) {
  //      this.router.navigate([this.routeBeforeLogIn]);
  //    }
  //  }



  authenticate(username, password, productId): Observable<AuthenticationResult> {
    return new Observable(observer => {
      this.comm.authCommunication.getUserDetails(username, password, productId).subscribe(
        (result) => {
          console.log('authResult', result);

          if (result.result === '1') {
            //            this.jumpBackToBefore();
            localStorage.setItem('token', result.jwt);
            localStorage.setItem('fullAuth', JSON.stringify(result));
            this.clientInfo = result.clientInfo;
            this.jwtHandler = new JWTHandler(result.jwt);
          }
          observer.next(result);
          observer.complete();

        }
      );
    }
    );
  }
  cleanStorage() {
    localStorage.setItem('token', null);
    localStorage.setItem('fullAuth', null);
    //       this.routeBeforeLogIn = null;
    this.router.navigate(['/public-area']);
    this.jwtHandler = null;
  }

  logOut(): Observable<any> {

    return new Observable(observer => {
      if (this.jwtHandler) {
        if (this.jwtHandler !== undefined && this.jwtHandler.jti) {
          this.comm.authCommunication.logOut(this.jwtHandler.jti).subscribe(
            (result) => {
              this.cleanStorage();
            }

          );
        } else {
          this.cleanStorage();

        }
      } else {
        this.cleanStorage();

      }
    }
    );
  }


  refreshTokenSimple(): Observable<any> {
    return new Observable(observer => {
      this.comm.authCommunication.refreshToken(this.getToken()).subscribe(
        data => {
          if (data['result'] !== '1') {
            this.logOut().subscribe();
          } else {
            this.jwtHandler = new JWTHandler(data['jwt']);
            localStorage.setItem('token', data['jwt']);
            observer.next(data);
            observer.complete();
          }
        });
    });
  }


  public logIn(loginResult) {
    //  this.jumpBackToBefore();

    this.jwtHandler = new JWTHandler(loginResult['jwt']);
  }

  refreshToken(state): Observable<any> {
    return new Observable(observer => {
      this.comm.authCommunication.refreshToken(this.getToken()).subscribe(
        data => {
          if (data['result'] === '1') {
            //     this.jumpBackToBefore();

            localStorage.setItem('token', data['jwt']);
            this.jwtHandler = new JWTHandler(data['jwt']);
            this.router.navigate([state.url]);
          } else {
            this.router.navigate(['/public-area']);
          }
          observer.next(data);
          observer.complete();
        });
    });
  }


}
