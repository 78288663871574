
import {AuthenticationService} from '../../../../../shared/Services/AuthenticationService/authentication.service';
import {AuthorCommunicationService} from '../../../../Services/Communication/author-communication-service';
import {FullDialogInfo} from '../../../../../shared/entities/Dialog/dialoginfo';
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-choose-dialog',
  templateUrl: './choose-dialog-dialog.component.html',
})
export class ChooseDialogDialogComponent implements OnInit {
  dialogs: Array<FullDialogInfo> = [];

  constructor(private dialogRef: MatDialogRef<ChooseDialogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected com: AuthorCommunicationService, protected auth: AuthenticationService) {
    this.ngOnInit();
  }
  close() {
    this.dialogRef.close();
  }




  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.com.dialogCommunication.getAllDialogs().subscribe(
        (variabeln: any) => {
          this.dialogs = variabeln["result"];
        }
      );
    } else {
      this.auth.refreshTokenSimple().subscribe(data => {
        this.com.dialogCommunication.getAllDialogs().subscribe(
          (variabeln: any) => {
            this.dialogs = variabeln["result"];
          }
        );
      });
    }


  }

  dialogChoosen(dialog: FullDialogInfo) {
    this.dialogRef.close(dialog.Id);
  }
}