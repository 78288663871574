
import {AuthenticationService} from '../../../shared/Services/AuthenticationService/authentication.service';
import {ChooseClauselFromDialogComponent} from '../../Chunks/UserDialogChunk/Dialog/choose-clausel-from-dialog/choose-clausel-from-dialog.component';
import {ChooseDialogDialogComponent} from '../../Chunks/UserDialogChunk/Dialog/choose-dialog-dialog/choose-dialog-dialog.component';
import {PickDialogDialogComponent} from '../../Chunks/UserDialogChunk/Dialog/pick-dialog-dialog/pick-dialog-dialog.component';
import {StepsOverviewComponent} from '../../Chunks/UserDialogChunk/Dialog/steps-overview/steps-overview.component';
import {ViewVariableShortComponent} from '../../Chunks/UserDialogChunk/Dialog/view-variable-short/view-variable-short.component';

import {PickVariableDialogComponent} from '../../Chunks/UserDialogChunk/pick-variable-dialog/pick-variable-dialog.component';
import {PopUp} from '../../../shared/Services/PopUpService/popup';
import {ClauselInfo} from '../../../shared/entities/Dialog/clausels';
import {FullDialogInfo} from '../../../shared/entities/Dialog/dialoginfo';
import {MatDialog} from '@angular/material';
import {Observable} from 'rxjs';
export class DialogPopUp extends PopUp {


  constructor(protected dialog: MatDialog, protected auth: AuthenticationService) {
    super(dialog, auth);
  }



  showStepsOverview(data): Observable<any> {
    this.dialogConfig.data = data;

    return new Observable(observer =>
      this.openDialog(observer, StepsOverviewComponent));
  }

  showVariableShort(clauelEntry): Observable<any> {
    this.dialogConfig.data = clauelEntry;

    return new Observable(observer =>
      this.openDialog(observer, ViewVariableShortComponent));

  }

  pickDialogWithKeinDialog(): Observable<FullDialogInfo> {
    return new Observable(observer =>
      this.openDialog(observer, PickDialogDialogComponent));
  }
  pickDialogId(): Observable<Number> {
    return new Observable(observer =>
      this.openDialog(observer, ChooseDialogDialogComponent));
  }

  private chooseClauselFromDialogComponent(data): Observable<ClauselInfo> {
    this.dialogConfig.data = data;
    console.log(data, "pickedDialog");

    return new Observable(observer =>
      this.openDialog(observer, ChooseClauselFromDialogComponent));

  }

  mirrorClauselDialogComponent(): Observable<ClauselInfo> {
    return new Observable(observer => {
      this.pickDialogId().subscribe((data) => {
        if (data) {
          this.chooseClauselFromDialogComponent(data).subscribe((dataNorm) => {
            this.closeDialog(observer, dataNorm);
          });
        } else {
          this.dialog.closeAll();
        }
      });
    }

    );
  }

}
