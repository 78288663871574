import {AuthenticationService} from '../../../../shared/Services/AuthenticationService/authentication.service';
import {Component, OnInit, Input} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute, ActivationStart} from '@angular/router';

@Component({
  selector: 'app-got-to-author-area',
  templateUrl: './got-to-author-area.component.html',
  styleUrls: ['./got-to-author-area.component.css']
})
export class GotToAuthorAreaComponent implements OnInit {

  @Input() navigateTo;
  isAuthor = false;
  isRunning = false;
  constructor(private auth: AuthenticationService, private router: Router) {}

  ngOnInit() {
    if (this.auth.getToken() && this.auth.jwtHandler !== undefined) {
      //    console.log(this.auth.jwtHandler,"jwtHandler");
      //      this.isAuthor = (this.auth.clientInfo.role === '2');
      this.isAuthor = this.auth.jwtHandler.isAtLeastAuthor();
    }


  }



}

