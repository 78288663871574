import { AuthenticationService } from './authentication.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService, private router: Router) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        //console.log('response', event);
        if (event.body) {
          if (event.body['result'] === '-3' || event.body['result'] === '-1') {
            this.auth.logOut().subscribe(
              test => {
                console.log("test", test);
              }
            );
          }
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.auth.collectFailedRequest(request);
          this.auth.refreshToken(this.router.routerState.snapshot).subscribe(data => {
            //       location.reload();
            this.auth.retryFailedRequests();
          });
        }
      }
    });
  }
}
