import {AuthenticationService} from '../../../shared/Services/AuthenticationService/authentication.service';
import {Communication} from '../../../shared/Services/communication';
import {CoreCommunicationService} from '../../../core/Services/CommunicationService/corecommunicationservice';
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-login-credentials-dialog',
  templateUrl: './login-credentials-dialog.component.html',
  styleUrls: ['./login-credentials-dialog.component.css']
})
export class LoginCredentialsDialogComponent implements OnInit {
  username = "";
  password = "";
  error = '';
  inputData: string;
  constructor(
    private dialogRef: MatDialogRef<LoginCredentialsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private com: CoreCommunicationService,
    private auth: AuthenticationService) {

    if (data) {
      this.inputData = data;

    }
  }

  ngOnInit() {
  }

  authenticate(username, password) {

    this.auth.authenticate(username, password, this.inputData).subscribe(data => {
      if (data.result === '-1') { //Falsche Credentials
        this.error = ('Falscher Benutzername oder Passwort');

      }
      if (data.result === '-2') { //zu viele Logins
        this.error = ('Es sind zu viele Personen auf Ihrem Account Eingeloggt');

      }
      if (data.result === '1') {
        this.auth.logIn(data);
        this.dialogRef.close(true);
      }
    });
  }

  onClickSubmit(data) {
    const username = data.username;
    const password = data.password;
    if (localStorage['token']) {
      this.com.authCommunication.refreshToken(this.auth.getToken()).subscribe(refreshResult => {
        if (refreshResult['result'] !== '1') {
          this.auth.logOut().subscribe();
          this.authenticate(username, password);
        } else {
          this.auth.logIn(refreshResult);
          this.auth.retryFailedRequests();
          this.dialogRef.close(true);
        }
      });
    } else {
      this.authenticate(username, password);
    }

  }


  close() {
    this.dialogRef.close(false);
  }
}

