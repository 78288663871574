import {Rechtssprechung} from '../../../../shared/entities/rechtssprechung';
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-verdic-preview-popup',
  templateUrl: './verdic-preview-popup.component.html',
  styleUrls: ['./verdic-preview-popup.component.css']
})
export class VerdicPreviewPopupComponent implements OnInit {

  inputData = {
    Aktenzeichen: '', Art: 'Beschluss', AutorId:
    "-1", Datum: "", Gericht: '',
    Id: '-1', Leitsatz: '', Normen: '', Text: ''
  } as Rechtssprechung;
  constructor(private dialogRef: MatDialogRef<VerdicPreviewPopupComponent>, @Inject(MAT_DIALOG_DATA) data) {
    if (data) {
      this.inputData = data;
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
