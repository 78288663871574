import { Component, OnInit, Input } from '@angular/core';
import { Rechtssprechung } from '../../entities/rechtssprechung';

@Component({
  selector: 'app-view-verdic',
  templateUrl: './view-verdic.component.html',
  styleUrls: ['./view-verdic.component.css']
})
export class ViewVerdicComponent implements OnInit {
  @Input() verdic: Rechtssprechung = {
    Aktenzeichen: '', Art: '',
    AutorId: '', Datum: '', Gericht: '',
    Id: '', Leitsatz: '', Normen: '', Text: ''
  };
  constructor() { }

  ngOnInit() {
  }

}
