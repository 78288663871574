import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-header',
  templateUrl: './star-header.component.html',
  styleUrls: ['./star-header.component.css']
})
export class StarHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
