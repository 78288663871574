import {AbboToShow} from '../../../booking/Entities/abbotoshow';
import {AuthenticationResult} from '../../entities/authenticationresult';
import {Communication} from '../communication';
import {LoginCredentials} from '../../entities/logincredentials';
import {HttpClient} from '@angular/common/http';
import {Http} from '@angular/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
export class AuthenticationCommunication extends Communication {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }


  getUserDetails(username, password, productId): Observable<AuthenticationResult> {
    const jsonLogIn = {'username': username, 'password': password, 'productId': productId};
    const url = this.authenticationUrlClient + '?page_key=authentication';
    return this.httpClient.post(url, JSON.stringify(jsonLogIn)) as Observable<AuthenticationResult>;
  }

  getProductsForLogIn(): Observable<any> {
    const url = (this.htkUrl + 'page_key=project');
    return this.httpClient.get(url) as Observable<any>;
  }

  refreshToken(jwt): Observable<any> {
    const json = {'jwt': jwt};
    const url = this.authenticationUrlClient + '?page_key=refreshToken';
    return this.httpClient.post(url, JSON.stringify(json)) as Observable<any>;
  }


  logOut(jti): Observable<any> {
    const url = this.authenticationUrlClient + '?page_key=authentication&jti=' + jti;
    return this.httpClient.delete(url) as Observable<any>;
  }

  activateKey(username, password, code): Observable<any> {
    const json = {
      'username': username,
      'nmvKey': code,
      'password': password,
    };

    const url = this.authenticationUrlClient + '?page_key=client&subService=newClient&subSubService=clientSetItsCredentials';
    return this.httpClient.post(url, JSON.stringify(json)) as Observable<any>;
  }

  sendNewAbboToAuthServer(abbo): Observable<any> {
    const url = this.authenticationUrlAdmin + '?page_key=client&subService=newClient&subSubService=newClientFromNMV';
    return this.httpClient.post(url, JSON.stringify(abbo)) as Observable<any>;
  }

  deleteAbbo(clientId, productId): Observable<any> {
    const url = this.authenticationUrlAdmin + '?page_key=client&subService=products&clientId=' + clientId + '&productId=' + productId;
    return this.httpClient.delete(url) as Observable<any>;
  }


  updateAbbo(clientId, productId, abbo: AbboToShow): Observable<any> {
    const url = this.authenticationUrlAdmin + '?page_key=client&subService=products&clientId=' + clientId + '&productId=' + productId;
    return this.httpClient.put(url, JSON.stringify(abbo)) as Observable<any>;
  }



  updateLoginUsername(clientId, productId, newUsername): Observable<string> {
    const url = this.authenticationUrlClient + '?page_key=client&subService=products&clientId=' +
      clientId + '&productId=' + productId + '&username=' + newUsername;
    return this.httpClient.put(url, JSON.stringify(newUsername)) as Observable<string>;
  }
  updateLoginPassword(clientId, productId, newPassword, _oldPassword): Observable<string> {
    const oldPassword = {'oldPassword': _oldPassword};
    const url = this.authenticationUrlClient + '?page_key=client&subService=products&clientId=' +
      clientId + '&productId=' + productId + '&password=' + newPassword;
    return this.httpClient.put(url, JSON.stringify(oldPassword)) as Observable<string>;
  }
  checkIfNameIsUnique(username): Observable<string> {
    const url = this.authenticationUrlClient + '?page_key=client&subService=products&username=' + username;
    return this.httpClient.get(url) as Observable<string>;
  }

  resetAbboCredentials(clientId, productId): Observable<string> {
    const url = this.authenticationUrlClient + '?page_key=client&subService=products&clientId=' +
      clientId + '&productId=' + productId;
    return this.httpClient.delete(url) as Observable<string>;
  }



  checkIfPasswordisCorrect(clientId, productId, _password): Observable<string> {
    const password = {'password': _password};
    const url = this.authenticationUrlClient + '?page_key=client&subService=products&clientId=' +
      clientId + '&productId=' + productId;
    return this.httpClient.post(url, JSON.stringify(password)) as Observable<string>;
  }

  logOutAll(clientId): Observable<string> {
    const url = this.authenticationUrlClient + '?page_key=authentication&clientId=' + clientId;
    return this.httpClient.delete(url) as Observable<string>;
  }


}
