import {Communication} from '../../../shared/Services/communication';
import {FullNormInformation, Erlaeuterung} from '../../../shared/entities/Kommentierung/kommentar';
import {HttpClient} from '@angular/common/http';
import {Http} from '@angular/http';
import {Observable} from 'rxjs';
export class KommentarCommunication extends Communication {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  GetNormFullInformation(law: string, paragraph: string): Observable<FullNormInformation> {
    const url = (this.authorUrl + 'norm/' + law + '/' + (paragraph));
    return this.httpClient.get(url) as Observable<FullNormInformation>;
  }


  GetNormErlaeuterung(law: string, paragraph: string): Observable<Array<Erlaeuterung>> {
    const url = (this.authorUrl + 'erlaeuterung/' + law + '/' + (paragraph));
    return this.httpClient.get(url) as Observable<Array<Erlaeuterung>>;
  }

  GetSingleErlaeuterung(law: string, paragraph: string): Observable<Array<Erlaeuterung>> {
    const url = (this.authorUrl + 'erlaeuterung/' + law + '/' + (paragraph));
    return this.httpClient.get(url) as Observable<Array<Erlaeuterung>>;
  }

  postLink(tableType, json, law, paragraf): Observable<boolean> {
    const endpoint = this.authorUrl + 'upload/' + law + '/' + paragraf + '/' + tableType;
    return this.httpClient.post(endpoint, JSON.stringify(json)) as Observable<boolean>;
  }


  putErlaeuterungen(law: string, paragraph: string, allErl: Array<Erlaeuterung>) {
    const url = (this.authorUrl + 'erlaeuterung/' + law + '/' + (paragraph));
    return this.httpClient.put(url, JSON.stringify(allErl));
  }

  postFile(fileToUpload: File, tableType, kurzansicht, law, paragraf): Observable<boolean> {
    const endpoint = this.authorUrl + 'upload/' + law + '/' + paragraf + '/' + tableType;
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, kurzansicht);
    return this.httpClient
      .post(endpoint, formData) as Observable<boolean>;
  }




  updateUploadAble(tableType, uploadable, id): Observable<boolean> {
    const url = this.authorUrl + 'upload/' + tableType + '/' + id;
    return this.httpClient.put(url, JSON.stringify(uploadable)) as Observable<boolean>;

  }

 

  getUploadAble(tableType, id): Observable<any> {
    const url = this.authorUrl + 'upload/' + tableType + '/' + id;
    alert(url);
    return this.httpClient.get(url) as Observable<any>;
  }

  deleteUploadAble(tableType, id): Observable<boolean> {
    const url = this.authorUrl + 'upload/' + tableType + '/' + id;
    return this.httpClient.delete(url) as Observable<boolean>;
  }









  NmvGetAllLaws(): Observable<any> {
    const url = 'https://www.neuer-medienverlag.de/htk2/htk-php/htkMobile.php?w=vListe&koerperschaft=d&id_projekt=1';
    //console.log("test", this.httpClient.jsonp(url, "JSONP_CALLBACK").map(resp => resp.valueOf));

    return this.httpClient.get(url) as Observable<any>;
  }
  NmvGetAllNorms(law): Observable<any> {
    const url = 'https://www.neuer-medienverlag.de/htk2/htk-php/htkMobile.php?w=gi&gesetz=' + law + '&datum=15.05.2018&id_projekt=1';
    return this.httpClient.get(url) as Observable<any>;
  }


}