import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-preview-erlaeuterun',
  templateUrl: './preview-erlaeuterun.component.html',
  styleUrls: ['./preview-erlaeuterun.component.css']
})
export class PreviewErlaeuterungComponent implements OnInit {
  inputData = {headline: "", text: ""};
  constructor(private dialogRef: MatDialogRef<PreviewErlaeuterungComponent>, @Inject(MAT_DIALOG_DATA) data) {
    if (data) {
      this.inputData = data;
    }
  }

  ngOnInit() {
  }





  close() {
    this.dialogRef.close();
  }
}
