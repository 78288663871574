import {AuthorCommunicationService} from '../../../../Services/Communication/author-communication-service';
import {UploadPdfDialogComponent} from '../upload-pdf-dialog/upload-pdf-dialog.component';
import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-new-link-dialog',
  templateUrl: './new-link-dialog.component.html',
  styleUrls: ['./new-link-dialog.component.css']
})
export class NewLinkDialogComponent implements OnInit {
  link: '';
  kurzansicht = '';
  constructor(private com: AuthorCommunicationService,
    private dialogRef: MatDialogRef<NewLinkDialogComponent>) {}

  ngOnInit() {
  }

  save() {
    const returnMe = {
      Kurzansicht: this.kurzansicht,
      Path: this.link
    };

    console.log('FILE', returnMe.Path);
    if (this.kurzansicht.length === 0) {
      alert('Keine Kurzansicht eingegeben');
      return;
    }
    if (this.link === '') {
      alert('Kein Link angegeben');
      return;
    }
    this.dialogRef.close(returnMe);

  }

  close() {
    this.dialogRef.close();
  }
}