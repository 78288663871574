import {AuthenticationService} from '../../../shared/Services/AuthenticationService/authentication.service';
import {CoreCommunicationService} from '../../Services/CommunicationService/corecommunicationservice';
import {Product} from '../../../shared/entities/authenticationresult';

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {
  username = "";
  password = "";
  products = [] as Array<Product>;
  choosenProduct = -1;
  constructor(private router: Router, private auth: AuthenticationService,
    private com: CoreCommunicationService) {}

  ngOnInit() {

    this.com.authCommunication.getProductsForLogIn().subscribe(
      data => {
        this.products = data['result'];
      }
    );
  }
  private authenticate(data) {
    const username = data.username;
    const password = data.password;
    this.auth.authenticate(username, password, this.products[this.choosenProduct]).subscribe(data => {
      if (data.result === '-1') { //Falsche Credentials
        alert("Falscher Benutzername oder Passwort");

      }
      if (data.result === '-2') { //zu viele Logins
        alert("Es sind zu viele Personen auf Ihrem Account Eingeloggt");

      }
      if (data.result === '1') {
        this.router.navigate(['/Start']);
      }
    });
  }



  onClickSubmit(data) {
    if (this.choosenProduct === -1) {
      alert("Bitte Wählen Sie ein Projekt");
    } else {
      if (localStorage['token']) {
        this.com.authCommunication.refreshToken(this.auth.getToken()).subscribe(refreshResult => {
          console.log(refreshResult, 'refreshResult');
          if (refreshResult['result'] !== '1') {
            this.auth.logOut().subscribe();
            this.authenticate(data);
          } else {
            this.router.navigate(['/Start']);
          }

        });
      } else {
        this.authenticate(data);
      }

    }

  }

}
