import {Component, OnInit, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'app-view-help',
  templateUrl: './view-help.component.html',
  styleUrls: ['./view-help.component.css']
})
export class ViewHelpComponent implements OnInit {
  printText = '';
  textArray = [] as Array<string>;
  rnrArray = [] as Array<number>;
  @Input() headline;
  constructor() {}

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }
  @Input()
  set text(newText) {
    delete this.printText;
    delete this.textArray;
    delete this.rnrArray;

    this.printText = newText;

    this.changes();
  }

  changes() {

    this.textArray = [];
    this.rnrArray = [];
    this.printText = this.replaceAll(this.printText, '<br>', '\n ');
    this.printText = this.replaceAll(this.printText, '<p>', '');
    this.printText = this.replaceAll(this.printText, '</p>', '\n');

    this.textArray = this.printText.split('\n');

    for (let i = 0; i < this.printText.length; ++i) {
      this.rnrArray.push(-1);
    }
    let rnrCounter = 1;

    for (let i = 0; i < this.textArray.length - 1; ++i) {
      let wert = this.textArray[i];
      if (wert) {
        let noCite = false;
        if (wert.match(/Urteil v\.|Beschluss v\.|Beschluss vom|Urteil vom|Gerichtsbescheid|Kammerbeschluss|Nichtannahmebeschluss/)) {
          wert = '\n<p class="zitat">' + wert + '</p>';
        } else if (wert.match(/-z-/)) {
          wert = wert.replace(/-z-/, '');
          wert = '\n<p class="zitat">' + wert + '</p>';
        } else {
          noCite = true;
        }
        if (noCite) {
          if (wert.match(/^\*/)) { // List
            wert = wert.replace(/^\*(.*)/,
              '\n<p class=\'liste1\'><img src=\'../htk-css/ecke.gif\' width=\'12\' height=\'9\' border=\'0\' >$1</p>');
          } else if (wert[0].match(/\d/)) { // Headline
            if (wert.match(/\d.\d/)) {
              wert = '<h3>' + wert + '</h3>';
            } else {
              wert = '<h2>' + wert + '</h2>';
            }
          } else { // Paragraph
            if (wert[0] !== '<' && wert.length > 3) {
              this.rnrArray[i] = rnrCounter;
              rnrCounter++;
            }
          }
        }
        this.textArray[i] = wert;
      } else {
        this.textArray[i] = '<br>';
      }
    }
  }
  ngOnInit() {
  }

}