
import {Communication} from '../../../shared/Services/communication';
import {Rechtssprechung} from '../../../shared/entities/rechtssprechung';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';



export class RechtssprechungCommunication extends Communication {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }



  deleteSingle(id): Observable<Rechtssprechung> {
    const url = (this.authorUrl + 'rechtssprechung/' + id);
    return this.httpClient.delete(url) as Observable<Rechtssprechung>;
  }


  updateSingle(id, json): Observable<any> {
    const url = (this.authorUrl + 'rechtssprechung/' + id);
    return this.httpClient.put(url, JSON.stringify(json)) as Observable<any>;
  }

  getSingle(id): Observable<Rechtssprechung> {
    const url = (this.authorUrl + 'rechtssprechung/' + id);
    return this.httpClient.get(url) as Observable<Rechtssprechung>;
  }

  postNewVerdic(json): Observable<any> {
    const endpoint = this.authorUrl + 'rechtssprechung/';
    return this.httpClient.post(endpoint, JSON.stringify(json)) as Observable<any>;
  }

}
