
import {ClauselInfo, ClauselEntry} from '../../../shared/entities/Dialog/clausels';
import {FullDialogInfo} from '../../../shared/entities/Dialog/dialoginfo';
import {Module} from '../../../dialog-system/Entities/module';
import {Communication} from '../../../shared/Services/communication';
import {HttpClient} from '@angular/common/http';
import {Http} from '@angular/http';
import {Observable} from 'rxjs';

export class DialogCommunication extends Communication {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }



  getFullSingleDialog(dialogId: number): Observable<FullDialogInfo> {
    const url = this.authorUrl + 'dialog/' + dialogId;
    return this.httpClient.get(url) as Observable<FullDialogInfo>;
  }

  getAllModules(): Observable<Array<Module>> {
    const url = this.authorUrl + 'modules/';
    return this.httpClient.get(url) as Observable<Array<Module>>;
  }

  getAllClauselInfosOfDialog(dialogId): Observable<ClauselInfo> {
    const url = this.authorUrl + 'clauselinfo/allofdialog/' + dialogId;
    return this.httpClient.get(url) as Observable<ClauselInfo>;
  }
  getClauselsVariables(clauselId): Observable<Array<ClauselEntry>> {
    const url = this.authorUrl + 'clauselinfo/getclauselsvariables/' + clauselId;
    return this.httpClient.get(url) as Observable<Array<ClauselEntry>>;
  }
  requestChangedDialogThroughMirroring(clauselId): Observable<Array<string>> {
    const url = this.authorUrl + 'clauselinfo/includeddialogs/' + clauselId;
    return this.httpClient.get(url) as Observable<Array<string>>;
  }



  postNewDialog(jsonDialog: any): Observable<any> {
    const url = this.authorUrl + 'dialog/';
    return this.httpClient.post(url, JSON.stringify(jsonDialog));
  }

  putDialogChanges(jsonDialog, dialogId): Observable<any> {
    const url = this.authorUrl + 'dialog/' + dialogId;
    return this.httpClient.put(url, JSON.stringify(jsonDialog)
    ) as Observable<any>;
  }





  getAllDialogs(): Observable<Array<FullDialogInfo>> {
    const url = this.authorUrl + 'dialoginfo/';
    return this.httpClient.get(url) as Observable<Array<FullDialogInfo>>;
  }


  getMyDialogs(authorId: number): Observable<Array<FullDialogInfo>> {
    const url = this.authorUrl + 'dialoginfo/author/' + authorId;
    return this.httpClient.get(url) as Observable<Array<FullDialogInfo>>;
  }


}