
import {HelpDialogComponent} from '../../Chunks/UserDialogChunk/HelpMessages/help-dialog/help-dialog.component';
import {PickVariableDialogComponent} from '../../Chunks/UserDialogChunk/pick-variable-dialog/pick-variable-dialog.component';
import {RequestYesNoComponent} from '../../../shared/Services/PopUpService/request-yes-no/request-yes-no.component';
import {VerdicPreviewPopupComponent} from '../../Chunks/UserDialogChunk/verdic-preview-popup/verdic-preview-popup.component';
import {AuthenticationService} from '../../../shared/Services/AuthenticationService/authentication.service';
import {PopUp} from '../../../shared/Services/PopUpService/popup';
import {Verweis} from '../../../shared/entities/Kommentierung/kommentar';
import {FullVariable} from '../../../shared/entities/Dialog/variable';
import {MatDialog} from '@angular/material';
import {Observable} from 'rxjs';
export class MiscPopUp extends PopUp {


  constructor(protected dialog: MatDialog, protected auth: AuthenticationService) {
    super(dialog, auth);
  }



  gesetzThenNotm(): Observable<Verweis> {
    return new Observable(observer => this.openDialog(observer, RequestYesNoComponent));
  }

  pickVariable(): Observable<FullVariable> {
    return new Observable(observer =>
      this.openDialog(observer, PickVariableDialogComponent));
  }

  openHelpPopUp(input): Observable<boolean> {
    this.dialogConfig.data = input;
    return new Observable(observer => this.openDialog(observer, HelpDialogComponent));
  }

  verdicPreview(input): Observable<FullVariable> {
    this.dialogConfig.data = input;
    return new Observable(observer =>
      this.openDialog(observer, VerdicPreviewPopupComponent));
  }




}