import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {LoginCredentialsDialogComponent} from './public-area/Dialog/login-credentials-dialog/login-credentials-dialog.component';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';




@NgModule({
  declarations: [
    AppComponent,
    LoginCredentialsDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    FormsModule,


  ],
  exports: [],
 
  providers: [],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [LoginCredentialsDialogComponent]

})
export class AppModule {}
