import {AuthenticationService} from '../../../../shared/Services/AuthenticationService/authentication.service';
import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, RoutesRecognized, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  projekt = 'AuslR';
  isLogin = false;
  isRunning = true;
  lastProjekt = "AuslR";
  constructor(private auth: AuthenticationService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {

    this.router.events.subscribe((data) => {
 
      if (data instanceof NavigationEnd) {
        if (data['url']) {

          const params = data['url'].split('/');

          switch (params[1]) {
            case "public-area":
              this.projekt = 'public-area';
              break;
            case "author":
              this.projekt = 'Author';
              break;
            case "booking-area":
              this.projekt = 'booking-area';
              break;
            case "htk":
              this.projekt = params[2];
              break;
            case "dialog":
              this.projekt = params[2];
              break;
            case "account":
              this.projekt = '';
              break;
            default:

              this.projekt = 'public-area';
              break;
          }
        }
      }
    });
  }
}
