import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-steps-overview',
  templateUrl: './steps-overview.component.html',
  styleUrls: ['./steps-overview.component.css']
})
export class StepsOverviewComponent implements OnInit {
  inputData: Array<Array<string>> = [];
  constructor(
    private dialogRef: MatDialogRef<StepsOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data) {
      this.inputData = data;

    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }


}
