
import {CoreCommunicationService} from '../../../core/Services/CommunicationService/corecommunicationservice';
import {DialogCommunication} from './dialogcommunication';
import {HelpCommunication} from './helpcommunication';
import {KommentarCommunication} from './kommentarcommunication';
import {RechtssprechungCommunication} from './rechtssprechungcommunication';
import {VariableCommunication} from './variablecommunication';
import {HttpRequest} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Http, RequestOptions, Headers, HttpModule, Jsonp} from '@angular/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class AuthorCommunicationService extends CoreCommunicationService {
  kommentarCommunication: KommentarCommunication;
  dialogCommunication: DialogCommunication;
  variableCommunication: VariableCommunication;
  helpCommunication: HelpCommunication;
  verdicCommunication: RechtssprechungCommunication;

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.kommentarCommunication = new KommentarCommunication(httpClient);
    this.dialogCommunication = new DialogCommunication(httpClient);
    this.variableCommunication = new VariableCommunication(httpClient);
    this.helpCommunication = new HelpCommunication(httpClient);
    this.verdicCommunication = new RechtssprechungCommunication(httpClient);


  }


  sendRequest(req: HttpRequest<any>): Observable<any> {
    return this.httpClient.request(req);
  }






}
