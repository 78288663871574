
import {AuthenticationService} from '../../../../shared/Services/AuthenticationService/authentication.service';
import {VariableListEngingeComponent} from '../../../Models/variable-list-enginge.component';
import {AuthorCommunicationService} from '../../../Services/Communication/author-communication-service';
import {FullVariable} from '../../../../shared/entities/Dialog/variable';
//import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';

import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-pick-variable-dialog',
  templateUrl: './pick-variable-dialog.component.html',
  styleUrls: ['./pick-variable-dialog.component.css']
})
export class PickVariableDialogComponent extends VariableListEngingeComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<FullVariable>,
    @Inject(MAT_DIALOG_DATA) data,
    protected com: AuthorCommunicationService, protected user: AuthenticationService) {
    super(com, user);
    this.getVariables();

  }
  close() {
    this.dialogRef.close();
  }

  toggleVariables() {
    this.page = 0;
    this.pickMyVariables = !this.pickMyVariables;
    this.getVariables();
  }


  ngOnInit() {
    this.getVariables();
  }

  variableChoosenClicked(variable) {
    this.dialogRef.close(variable);
  }

}
