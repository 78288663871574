
import {AuthorCommunicationService} from '../../../../Services/Communication/author-communication-service';
import {Erlaeuterung} from '../../../../../shared/entities/Kommentierung/kommentar';
import {OnInit, Inject, Component} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
@Component({
  selector: 'app-new-erlaeuterung-dialog',
  templateUrl: 'new-erlaeuterung-dialog.component.html',
  styleUrls: ['./new-erlaeuterung-dialog.component.css']
})
export class NewErlaeuterungDialogComponent implements OnInit {
  erlaeuterung: Erlaeuterung = {
    Headline: '',
    Id: '-1',
    Kurzansicht: '',
    VerweiseVariable: []
  };
  constructor(
    private dialogRef: MatDialogRef<NewErlaeuterungDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data, private comm: AuthorCommunicationService) {

  }

  ngOnInit() {
  }
  save() {
    this.dialogRef.close(this.erlaeuterung);
  }

  close() {
    this.dialogRef.close();
  }
}
