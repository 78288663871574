import {CoreCommunicationService} from '../Services/CommunicationService/corecommunicationservice';
import {AuthenticationService} from '../../shared/Services/AuthenticationService/authentication.service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate} from '@angular/router';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AdminGuard implements CanActivate {

  constructor(private router: Router,
    private auth: AuthenticationService,
    private comm: CoreCommunicationService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    if (this.auth.jwtHandler === undefined) {
      this.router.navigate(['/public-area']);

      return false;
    }
    if (!this.auth.jwtHandler.isAdmin()) {
      this.router.navigate(['/public-area']);
      return false;
    }

    if (!this.auth.isAuthenticated()) {
      if (this.auth.getToken()) {
        this.auth.refreshToken(state).subscribe(test => {
        });
      } else {
        this.router.navigate(['/public-area']);
      }
      return false;
    } else {
      return true;
    }
  }
}