import { Component, OnInit, Input } from '@angular/core';
import { BillToPrint } from '../../../booking/Entities/billtoprint';
import { ClientInfos } from '../../../booking/Entities/bookingclient';
import { Product } from '../../entities/product';
import { AbboToShow } from '../../../booking/Entities/abbotoshow';

@Component({
  selector: 'shared-bill-container',
  templateUrl: './bill-container.component.html',
  styleUrls: ['./bill-container.component.css']
}) 
export class BillContainerComponent implements OnInit {
  @Input() bill: BillToPrint;
  @Input() client: ClientInfos;
  @Input() product: Product;
  @Input() abbo: AbboToShow;

  // date = new Date();
  // dd;
  // mm;
  // yyyy;
  constructor() { }

  ngOnInit() {
    // if (this.bill) {
    //   this.date = new Date(this.bill.Datum);
    // }else{
    //   this.date = new Date();

    // }
    // this.dd = this.date.getDate();
    // this.mm = this.date.getMonth();
    // this.yyyy = this.date.getFullYear();
  }

}
