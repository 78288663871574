import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-edit-uploadable-dialog',
  templateUrl: './edit-uploadable-dialog.component.html',
  styleUrls: ['./edit-uploadable-dialog.component.css']
})
export class EditUploadableDialogComponent implements OnInit {
  kurzansicht = '';
  constructor(private dialogRef: MatDialogRef<EditUploadableDialogComponent>) {}

  ngOnInit() {
  }



  save() {

    this.dialogRef.close(this.kurzansicht);

  }

  close() {
    this.dialogRef.close();
  }

}
