import {AuthorCommunicationService} from '../../../../Services/Communication/author-communication-service';
import {Law} from '../../../../../shared/entities/nmventities';
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-pick-gesetz-dialog',
  templateUrl: './pick-gesetz-dialog.component.html',
  styleUrls: ['./pick-gesetz-dialog.component.css']
})

export class PickGesetzDialogComponent implements OnInit {
  laws: Array<Law>;
  lawTable: Array<Array<Law>> = [];

  constructor(
    private dialogRef: MatDialogRef<PickGesetzDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data, private comm: AuthorCommunicationService) {

  }

  ngOnInit() {
    this.comm.kommentarCommunication.NmvGetAllLaws().subscribe((data) => {
      console.log("recData -pickgesetz-dialog-", data);
      this.laws = data;
      for (let i = 0; i < this.laws.length - 4; i = i + 4) {
        let buff: Array<Law> = [];
        buff.push(this.laws[i]);
        buff.push(this.laws[i + 1]);
        buff.push(this.laws[i + 2]);
        buff.push(this.laws[i + 3]);
        this.lawTable.push(buff);
      }
    }
    );
  }

  save(pickedLaw) {
    this.dialogRef.close(pickedLaw);
  }

  close() {
    this.dialogRef.close();
  }
}
