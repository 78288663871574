import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-request-yes-no',
  templateUrl: './request-yes-no.component.html',
  styleUrls: ['./request-yes-no.component.css']
})
export class RequestYesNoComponent implements OnInit {
  inputData: string;
  constructor(
    private dialogRef: MatDialogRef<RequestYesNoComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data) {
      this.inputData = data;

    }
  }

  ngOnInit() {
  }

  yes() {
    this.dialogRef.close(1);
  }

  no() {
    this.dialogRef.close(0);
  }


  close() {
    this.dialogRef.close();
  }

}
