import {DialogPopUpService} from '../../../../author/Services/PopUpService/dialog-pop-up-service.service';
import {AuthenticationService} from '../../../../shared/Services/AuthenticationService/authentication.service';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {throws} from 'assert';

@Component({
  selector: 'app-author-topbar-view',
  templateUrl: './author-topbar-view.component.html',
  styleUrls: ['./author-topbar-view.component.css']
})
export class AuthorTopbarViewComponent implements OnInit {

  projekt = 'AuslR';

  constructor(private popup: DialogPopUpService, protected route: ActivatedRoute,
    private router: Router) {}



  ngOnInit() {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        let params = data.url.split('/');
        if (params.length > 2) {
          this.projekt = params[2];
        }
      }
    });
  }
  editErlaeuterung() {
    this.popup.kommentar.gesetzThenNorm().subscribe(
      (pickedNorm) => {
        this.router.navigate(['edit-erlaeuterungen/' + pickedNorm.GesetzName
          + '/' +
          pickedNorm.Bezeichnung + pickedNorm.Paragraph]);
      });
  }
  editNorm() {
    this.popup.kommentar.gesetzThenNorm().subscribe(
      (pickedNorm) => {
        this.router.navigate(['edit-norm/' + pickedNorm.GesetzName
          + '/' +
          pickedNorm.Bezeichnung + pickedNorm.Paragraph]);
      });
  }
}
