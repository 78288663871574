
import {AuthenticationService} from '../../../../../shared/Services/AuthenticationService/authentication.service';
import {AuthorCommunicationService} from '../../../../Services/Communication/author-communication-service';
import {ClauselInfo} from '../../../../../shared/entities/Dialog/clausels';
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-choose-clausel-from-dialog',
  templateUrl: './choose-clausel-from-dialog.component.html',
  styleUrls: ['./choose-clausel-from-dialog.component.css']
})
export class ChooseClauselFromDialogComponent implements OnInit {
  dialogId;
  clauselInfos: Array<ClauselInfo>;

  constructor(private dialogRef: MatDialogRef<ChooseClauselFromDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected com: AuthorCommunicationService,
    protected auth: AuthenticationService) {
    if (data) {

      this.ngOnInit();
      this.dialogId = data;
    }
  }
  close() {
    this.dialogRef.close();
  }


  handleOnInit() {


    this.com.dialogCommunication.getAllClauselInfosOfDialog(this.dialogId).subscribe(
      (variabeln: any) => {
        if (variabeln) {
          this.clauselInfos = variabeln["result"];

        }
      }
    );


  }



  ngOnInit() {
    if (this.dialogId) {
      this.clauselInfos = [];
      if (this.auth.isAuthenticated()) {
        this.handleOnInit();
      } else {
        this.auth.refreshTokenSimple().subscribe(data => {
          this.handleOnInit();
        });
      }

    }
  }

  variableChoosenClicked(clausel) {
    this.dialogRef.close(clausel);
  }


  clauselChoosen(clausel) {
    this.dialogRef.close(clausel);
  }
}
