import {FullVariable} from '../../../../../shared/entities/Dialog/variable';
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-view-variable-short',
  templateUrl: './view-variable-short.component.html',
  styleUrls: ['./view-variable-short.component.css']
})
export class ViewVariableShortComponent implements OnInit {
  variable: FullVariable = {
    AutorId: '', Bemerkung: '', EditGesetze: '', Headline: '',
    EditParagraphs: '', EditTags: '', FrageAnsicht: '', Id: ' ', Kommentar: '',
    ModulId: '', ModulName: '', Name: '', NegText: '', PosText: '', Tags: [], Verweise: []
  };

  constructor(
    private dialogRef: MatDialogRef<ViewVariableShortComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data) {
      this.variable = data;

    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }



}
