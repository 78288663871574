import { Component, OnInit, Input } from '@angular/core';
import { SharedCommunicationService } from '../../../Services/CommunicationService/shared-communication.service';

@Component({
  selector: 'app-view-bill-button',
  templateUrl: './view-bill-button.component.html',
  styleUrls: ['./view-bill-button.component.css']
})
export class ViewBillButtonComponent implements OnInit {
  @Input() billId = 0;
  constructor(private sharedCom: SharedCommunicationService) { }

  ngOnInit() {
  }
  viewBill() {
    this.sharedCom.getSingleBillPdf(this.billId).subscribe(response => { this.sharedCom.handleDownload(response, "Rechnung_" + this.billId + ".pdf") });

  }
}
