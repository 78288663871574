import {AuthenticationService} from '../../shared/Services/AuthenticationService/authentication.service';
import {AuthorCommunicationService} from '../Services/Communication/author-communication-service';
import {FullVariable} from '../../shared/entities/Dialog/variable';

import {Sort} from '@angular/material';
import {Observable} from 'rxjs';
export class VariableListEngingeComponent {
  variables = [] as Array<FullVariable>;
  pickMyVariables = true;
  sort = {
    active: 'Id',
    direction: 'asc'
  };
  page = 0;
  nameStringToFilter = '';

  constructor(protected com: AuthorCommunicationService, protected user: AuthenticationService) {}

  public filterResult() {
    this.page = 0;
    this.getVariables();

  }

  private decideWhichVariablesToGet(): Observable<any> {
    if (!this.pickMyVariables) {
      return this.com.variableCommunication.getAllVariablesByPageAndSearch(this.page, this.nameStringToFilter, this.sort);
    } else {
      return this.com.variableCommunication.
        getMyVariablesByPageAndSearch(this.page, this.nameStringToFilter, this.sort, this.user.clientInfo.clientId);
    }
  }
  private getVariablesByPage(): Observable<any> {
    if (this.user.isAuthenticated()) {
      return this.decideWhichVariablesToGet();
    } else {
      this.user.refreshTokenSimple().subscribe(data => {
        return this.decideWhichVariablesToGet();
      });
    }
  }

  public forward() {
    this.page++;
    this.variables = [];
    this.getVariables();
  }

  public backward() {
    this.page--;
    this.variables = [];

    this.getVariables();
  }

  public newVariablesFirst() {
    let newSort: Sort = {active: "Id", direction: "desc"};
    this.sortData(newSort);
  }



  public sortData(sort: Sort) {
    this.page = 0;
    this.sort = sort;
    this.getAndSortVariables(sort);

  }




  private getAndSortVariables(sort) {
    const data = this.variables.slice();
    this.getVariablesByPage().subscribe(
      (variabeln: any) => {

        this.variables = variabeln['result'];

        if (!sort.active || sort.direction === '') {
          this.variables = data;
          return;
        }

        this.variables = data.sort((a, b) => {
          let isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'Name': return compare(a.Name, b.Name, isAsc);
            case 'Id': return compare(+a.Id, +b.Id, isAsc);

            default: return 0;
          }
        });

      }
    );
  }





  public getVariables() {
    this.getVariablesByPage().subscribe(
      (variabeln: any) => {
        this.variables = variabeln['result'];
      }
    );
  }


}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


