
import {EditUploadableDialogComponent} from '../../Chunks/UserDialogChunk/Kommentar/edit-uploadable-dialog/edit-uploadable-dialog.component';
import {NewErlaeuterungDialogComponent} from '../../Chunks/UserDialogChunk/Kommentar/new-erlaeuterung-dialog/new-erlaeuterung-dialog.component';
import {NewLinkDialogComponent} from '../../Chunks/UserDialogChunk/Kommentar/new-link-dialog/new-link-dialog.component';
import {PickGesetzDialogComponent} from '../../Chunks/UserDialogChunk/Kommentar/pick-gesetz-dialog/pick-gesetz-dialog.component';
import {PickNormDialogComponent} from '../../Chunks/UserDialogChunk/Kommentar/pick-norm-dialog/pick-norm-dialog.component';
import {PreviewErlaeuterungComponent} from '../../Chunks/UserDialogChunk/Kommentar/preview-erlaeuterun/preview-erlaeuterun.component';
import {UploadPdfDialogComponent} from '../../Chunks/UserDialogChunk/Kommentar/upload-pdf-dialog/upload-pdf-dialog.component';
import {AuthenticationService} from '../../../shared/Services/AuthenticationService/authentication.service';

import {PopUp} from '../../../shared/Services/PopUpService/popup';
import {Erlaeuterung, Verweis} from '../../../shared/entities/Kommentierung/kommentar';
import {Law} from '../../../shared/entities/nmventities';
import {MatDialog} from '@angular/material';
import {Observable} from 'rxjs';
export class KommentierungDialog extends PopUp {


  constructor(protected dialog: MatDialog, protected auth: AuthenticationService) {
    super(dialog, auth);
  }




  newErlaeuterung(): Observable<Erlaeuterung> {
    return new Observable(observer =>
      this.openDialog(observer, NewErlaeuterungDialogComponent));
  }


  pickGesetz(): Observable<any> {
    return new Observable(observer =>
      this.openDialog(observer, PickGesetzDialogComponent));
  }


  editUploadable(): Observable<string> {
    return new Observable(observer =>
      this.openDialog(observer, EditUploadableDialogComponent));
  }


  uploadNewPdf(): Observable<any> {
    return new Observable(observer =>
      this.openDialog(observer, UploadPdfDialogComponent));
  }


  uploadNewLink(): Observable<any> {
    return new Observable(observer =>
      this.openDialog(observer, NewLinkDialogComponent));
  }


  pickNorm(law): Observable<any> {
    this.dialogConfig.data = law;
    return new Observable(observer =>
      this.openDialog(observer, PickNormDialogComponent));
  }



  previewErlaeuterung(headline, text): Observable<boolean> {
    this.dialogConfig.data = {"headline": headline, "text": text};
    return new Observable(observer => this.openDialog(observer, PreviewErlaeuterungComponent));
  }

  gesetzThenNorm(): Observable<Verweis> {
    return new Observable(observer => {
      this.pickGesetz().subscribe((data) => {
        let pickedLaw: Law = data;
        this.pickNorm(data.gesetz).subscribe((dataNorm) => {
          const newVerweis = {
            GesetzName: pickedLaw.gesetz,
            Paragraph: dataNorm.par,
            Bezeichnung: dataNorm.bez,
          };
          this.closeDialog(observer, dataNorm);
        });
      });
    });
  }

}