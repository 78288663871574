import {AuthorCommunicationService} from '../../../../Services/Communication/author-communication-service';
import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-upload-pdf-dialog',
  templateUrl: './upload-pdf-dialog.component.html',
  styleUrls: ['./upload-pdf-dialog.component.css']
})
export class UploadPdfDialogComponent implements OnInit {
  fileToUpload: File = null;
  kurzansicht = '';
  constructor(private com: AuthorCommunicationService,
    private dialogRef: MatDialogRef<UploadPdfDialogComponent>) {}

  ngOnInit() {
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  checkIfGoodFileName(fileName): boolean {
    if (fileName.includes('ö') || fileName.includes('ä') ||
      fileName.includes('ü') || fileName.includes('ß') ||
      fileName.includes('_') || fileName.includes('<') ||
      fileName.includes('§') || fileName.includes('%') ||
      fileName.includes('/') || fileName.includes('\\') ||
      fileName.includes('..') || fileName.includes('!')
    ) {
      return false;
    }
    return true;
  }


  save() {
    const returnMe = {
      Kurzansicht: this.kurzansicht,
      File: this.fileToUpload
    }

    console.log('FILE', returnMe.File);
    if (this.kurzansicht.length === 0) {
      alert('Keine Kurzansicht eingegeben');
      return;
    }
    if (this.fileToUpload === null) {
      alert('Keine Datei ausgewählt');
      return;
    }
    if (returnMe.File.type !== 'application/pdf') {
      alert('Keine PDF Ausgewählt!');
    } else {
      if (this.checkIfGoodFileName(returnMe.File.name)) {
        this.dialogRef.close(returnMe);

      } else {
        alert('Bitte keine Umlaute und Sonderzeichen im Datei-Namen!');

      }
    }

  }

  close() {
    this.dialogRef.close();
  }

}
