import {AuthorCommunicationService} from '../../../../Services/Communication/author-communication-service';
import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.css']
})
export class HelpDialogComponent implements OnInit {
  importHtml;
  //  @ViewChild('dataContainer') dataContainer: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<HelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data, private com: AuthorCommunicationService, private sanitizer: DomSanitizer) {
    this.importHtml = '';
    if (data) {

      this.com.helpCommunication.getHelp(data).subscribe(helpResult => {

        this.importHtml = helpResult;
      });
    }
  }

  ngOnInit() {
  }


  get saveUrl() {
    let data = 'http://www.juslab.de/AutorenSystemDialog/HilfeHtmlFiles/default.html';
    return this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }
  close() {
    this.dialogRef.close();
  }

}
