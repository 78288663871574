
import {AuthenticationService} from '../../../../../shared/Services/AuthenticationService/authentication.service';
import {AuthorCommunicationService} from '../../../../Services/Communication/author-communication-service';
import {FullDialogInfo} from '../../../../../shared/entities/Dialog/dialoginfo';
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-pick-dialog-dialog',
  templateUrl: './pick-dialog-dialog.component.html',
  styleUrls: ['./pick-dialog-dialog.component.css']
})
export class PickDialogDialogComponent implements OnInit {
  dialogs = [] as Array<FullDialogInfo>;
  constructor(private dialogRef: MatDialogRef<PickDialogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data, private com: AuthorCommunicationService, private auth: AuthenticationService) {}

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.com.dialogCommunication.getAllDialogs().subscribe(
        (dialogs: any) => {
          this.dialogs = dialogs['result'];
        }
      );
    } else {
      this.auth.refreshTokenSimple().subscribe(data => {
        this.com.dialogCommunication.getAllDialogs().subscribe(
          (dialogs: any) => {
            this.dialogs = dialogs['result'];
          }
        );
      });
    }
  }


  save(dialog) {
    this.dialogRef.close(dialog);
  }

  close() {
    this.dialogRef.close();
  }

}
