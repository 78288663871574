import { PickVariableDialogComponent } from '../../../author/Chunks/UserDialogChunk/pick-variable-dialog/pick-variable-dialog.component';
import { AuthenticationService } from '../AuthenticationService/authentication.service';
import { RequestYesNoComponent } from './request-yes-no/request-yes-no.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { LoginCredentialsDialogComponent } from '../../../public-area/Dialog/login-credentials-dialog/login-credentials-dialog.component';


export let AllDialogs = {
    Norm: {
        GesetzThenNorm: 'GesetzThenNorm',
    },
    Dialog: {
        NextDialog: 'NextDialog',

    },
    Misc: {},


};


export abstract class PopUp {
    protected dialogConfig = new MatDialogConfig();

    constructor(protected dialog: MatDialog, protected auth: AuthenticationService) {
        this.dialogConfig = new MatDialogConfig();

        this.dialogConfig.disableClose = true;
        this.dialogConfig.autoFocus = true;

    }




    protected closeDialog(observer, data) {
        this.dialog.closeAll();
        observer.next(data);
        observer.complete();
    }


    protected openDialog(observer, Type) {
        if (Type === LoginCredentialsDialogComponent) {

            console.log(this.auth.jwtHandler);
            //   this.dialog.open(Type);

            const dialogRefLaw = this.dialog.open(Type, this.dialogConfig);

            dialogRefLaw.afterClosed().subscribe((data) => this.closeDialog(observer, data));

        }
        if (this.auth.isAuthenticated() || this.auth.jwtHandler !== undefined) {

            // this.dialog.open(Type);

            const dialogRefLaw = this.dialog.open(Type, this.dialogConfig);

            dialogRefLaw.afterClosed().subscribe((data) => this.closeDialog(observer, data));
        } else {
            if (this.auth.jwtHandler !== undefined) {
                this.auth.refreshTokenSimple().subscribe(data_ => {
          //          this.dialog.open(Type);

                    const dialogRefLaw = this.dialog.open(Type, this.dialogConfig);

                    dialogRefLaw.afterClosed().subscribe((data) => this.closeDialog(observer, data));
                });
            }
        }
    }


    requestYesNo(input): Observable<boolean> {
        this.dialogConfig.data = input;
        return new Observable(observer => this.openDialog(observer, RequestYesNoComponent));
    }

}
