import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, RoutesRecognized} from '@angular/router';

@Component({
  selector: 'app-core-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  projekt = 'login';
  isAuthor = false;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        let params = data.url.split('/');
        if (params.length <= 2) {
          this.projekt = "login";
        }
        if (params[1] === 'author') {
          this.isAuthor = true;
        }
      }
    });
  }

}
