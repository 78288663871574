import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreCommunicationService } from '../../../core/Services/CommunicationService/corecommunicationservice';

@Injectable({
  providedIn: 'root'
})
export class SharedCommunicationService extends CoreCommunicationService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }


  getSingleBill(id): Observable<any> {
    const url = (this.billUrl + 'service=alteRechnung&Id=' + id);
    return this.httpClient.get(url) as Observable<any>;
  }
  getSingleBillPdf(id): Observable<any> {
    const url = this.billUrl + 'service=alteRechnung&subService=PDF&Id=' + id;
    return this.httpClient.get(url, { responseType: 'blob' as 'json' }) as Observable<any>;
  }
}
