import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log(request);
    // if (request.url.includes("https://www.neuer-medienverlag.com/Kundenverwaltung/php/")) {
    //   return next.handle(request);
    // }
    request = request.clone({
      setHeaders: {
        Authorization: `${this.auth.getToken()}`,
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Headers': 'authorization, Authorization, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
      }
    });

    return next.handle(request);


  }
}
