import {AuthorCommunicationService} from '../../../../Services/Communication/author-communication-service';
import {Norm} from '../../../../../shared/entities/nmventities';
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-pick-norm-dialog',
  templateUrl: './pick-norm-dialog.component.html',
  styleUrls: ['./pick-norm-dialog.component.css']
})
export class PickNormDialogComponent implements OnInit {
  normArray: Array<Norm>;
  inputData: string;
  constructor(
    private dialogRef: MatDialogRef<PickNormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data, private comm: AuthorCommunicationService) {
    if (data) {
      this.inputData = data;
      this.comm.kommentarCommunication.NmvGetAllNorms(this.inputData).subscribe(
        (newData) => {
          this.normArray = newData;
          this.normArray.shift();
          this.normArray.shift();

        }
      );
    }
  }

  ngOnInit() {
  }

  save(norm) {
    this.dialogRef.close(norm);
  }

  close() {
    this.dialogRef.close();
  }
}
