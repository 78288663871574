import { Rechtssprechung } from '../../../shared/entities/rechtssprechung';
import { AuthenticationCommunication } from '../../../shared/Services/AuthenticationService/authenticationcommunication';
import { Communication } from '../../../shared/Services/communication';
import { HttpRequest } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable()
export class CoreCommunicationService extends Communication {
    authCommunication: AuthenticationCommunication;

    constructor(protected httpClient: HttpClient) {
        super(httpClient);
        this.authCommunication = new AuthenticationCommunication(httpClient);
    }


    sendRequest(req: HttpRequest<any>): Observable<any> {
        return this.httpClient.request(req);
    }



    getAllVerdicsByPageAndSearch(page, az, gericht, text, norm, sort): Observable<Array<Rechtssprechung>> {
        const json = {
            'sort': sort,
            'query': {
                'Aktenzeichen': az,
                'Gericht': gericht,
                'Norm': norm,
                'Text': text,
            }
        };
        const url = (this.htkUrl + '?page_key=rechtssprechung&page=' + page);
        return this.httpClient.post(url, JSON.stringify(json)) as Observable<Array<Rechtssprechung>>;
    }


    getMyVerdicsByPageAndSearch(page, az, gericht, text, norm, sort, authorId): Observable<Array<Rechtssprechung>> {
        const json = {
            'sort': sort,
            'query': {
                'Aktenzeichen': az,
                'Gericht': gericht,
                'Norm': norm,
                'Text': text,

            }
        };
        const url = (this.authorUrl + '/rechtssprechung/suche/' + page + '/' + authorId);
        return this.httpClient.post(url, JSON.stringify(json)) as Observable<Array<Rechtssprechung>>;


    }
    handleDownload(response, fileName) {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        // tslint:disable-next-line:prefer-const
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();

    }

}


