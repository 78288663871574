import {AuthenticationService} from '../../../shared/Services/AuthenticationService/authentication.service';
import {DialogPopUp} from './dialogpopup';
import {KommentierungDialog} from './kommentierungdialog';
import {MiscPopUp} from './miscpopup';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class DialogPopUpService {
  public dialog: DialogPopUp;
  public misc: MiscPopUp;
  public kommentar: KommentierungDialog;
  constructor(private matDialog: MatDialog, protected auth: AuthenticationService) {

    this.dialog = new DialogPopUp(matDialog, auth);
    this.misc = new MiscPopUp(matDialog, auth);
    this.kommentar = new KommentierungDialog(matDialog, auth);


  }

}
